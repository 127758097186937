export enum VARIATIONS {
  Control = 'control',
  Treatment = 'treatment',
  Accessories = 'accessories',
  Apparel = 'apparel',
  Variation1 = 'variation_1',
  TabbedModule = 'variation',
}

export enum WHITELIST_USERS {
  InControl = 'inControl',
  InTreatment = 'inTreatment',
}

export enum EXPERIMENTS {
  MembershipCancellationFlow = 'membership_cancellation_flow',
  OrderCancellationFlow = 'order_cancellation_flow',
  AccessoriesVsApparelUpsell = 'memo_accessories_vs_apparel_upsell',
  DigitalCheckoutDownload = 'digital_checkout_download',
  ReferralProspectLandingPageModule = 'prospect_landing_page_content_module',
  ReDesignInterstitialPage = 're-design_interstitial_page',
  ChampQuickPayAppCheckout = 'champ_quick_pay_app_checkout',
}

export enum FEATURES {
  ShowPaymentOptions = 'show_payment_options',
  RemoteActivationPage = 'remote_activation_page',
  IsAccountTldSwitcherActive = 'is_account_tld_switcher_active',
  IsAppSignUpUpdatesEnabled = 'app_signup_updates',
  IsMembershipsPageActive = 'is_memberships_page_active',
  IsSelfServiceRebateFlowEnabled = 'is_self_service_rebate_flow_enabled',
  IsServicePlanPageActive = 'is_service_plan_active',
  IsOrderConfirmationPageActive = 'is_order_confirmation_page_active',
  IsSelfServiceExtendedWarrantiesEnabled = 'is_self_service_extended_warranties_enabled',
  IsAddAnotherServicePlanEnabled = 'is_add_another_service_plan_enabled',
  IsMembershipCheckoutActive = 'is_membership_checkout_active',
  IsDeliveryContactModuleEnabled = 'ox_is_delivery_contact_module_enabled',
  IsOrderTrackingPageActive = 'ox_order_tracking_page_active',
  IsLegacySessionEnabled = 'is_legacy_session_enabled',
  IsMemoryStorageEnabled = 'is_memory_storage_enabled',
  IsHideSetPasswordWhenSetEnabled = 'ox_hide_set_password_step',
  IsGuestOrderStatusSignInEnabled = 'ox_guest_order_status_sign_in_enabled',
  IsCanCancelPendingTooltipEnabled = 'ox_is_can_cancel_pending_tooltip_enabled',
  IsPostPurchaseUpsellExperimentEnabled = 'memo_upsell_experiment_enabled',
  IsGuestDeliveryInstructionHidden = 'ox_hide_guest_order_status_delivery_instructions',
  IsEcoFriendlySlotsEnabled = 'dx_eco_friendly_slots_enabled',
  IsUsingReactTracking = 'memo_is_using_react_tracking',
  IsReturnOrderItemsEnabled = 'ox_return_order_items',
  IsReturnsPageEnabled = 'ox_is_returns_page_enabled',
  IsOHSInitialQueryEnabled = 'ox_ohs_initial_query',
  IsFaaSCancellationEnabled = 'mem_faas_cancellation_enabled',
  IsUseSandboxChatbotForReturnsEnabled = 'sandbox_ada_support_bot_for_returns_enabled',
  IsReturnsExperienceRolloutEnabled = 'ox_returns_experience_rollout',
  IsShouldShowVatInvoiceLinkonOSEnabled = 'show_vat_invoice_link_on_order_status',
  IsCaptureMarketingConsentEnabled = 'is_capture_marketing_consent_enabled',
  IsReferralsCampaignEnabled = 'is_referrals_campaign_enabled',
  IsReferralsProspectModuleEnabled = 'is_referral_prospect_content_module_enabled',
  IsEssoEnabled = 'enable_sso_on_account',
  IsTierMobilityEnabled = 'is_tier_mobility_enabled',
  IsReferralProspectRowEnabled = 'is_referral_prospect_row_enabled',
  PrepaidAAM = 'prepaidAAM',
  ConfirmationAppPurchaseStep = 'confirmationappstep',
  IsAddressValidationEnabled = 'is_address_validation_enabled',
  CommerceToolsWarrantyCheckout = 'commerceToolsWarrantyCheckout',
  IsPauseExtensionEnabled = 'mem_pause_extension_enabled',
  IsProspectsGuestPassEnabled = 'isProspectsGuestPassEnabled',
  IsFaasPauseExtensionEnabled = 'faas_pause_extension_enabled',
  IsCancelPausedSubEnabled = 'mem_cancel_paused_enabled',
  IsOverrideDeliveryEstimateEnabled = 'odx_override_delivery_estimate_enabled',
  IsGuestPassOfferCodeExchangeEnabled = 'is_guest_pass_offer_code_exchange_enabled',
  isFreeCheckoutOauthEnabled = 'app_tiering_free_checkout_oauth_enabled',
  MyMembershipSubscriptionOrdering = 'my_membership_subscription_ordering',
  isGiftCardMyMembershipEnabled = 'gift_card_mymembership',
  isGiftCardConfirmationPageEnabled = 'gift_card_confirmation_page',
  IsATLocaleEnabled = 'at_enable_locale',
  IsLegacyReactivationBlocked = 'is_legacy_reactivation_blocked',
  IsReducedPauseLengthsEnabled = 'mem_reduced_paused_lengths_enabled',
  HumanSecurityJsSnippet = 'human_security_js_snippet',
  IsLegacySubRejoinLinkVisible = 'is_legacy_sub_rejoin_link_visible',
  IsAppMembershipGiftCardEnabled = 'app_membership_gift_card',
  IsHyattOfferConnectEnabled = 'is_hyatt_offer_connect_enabled', // EM-2330 for baseline
  IsAppFreeEmailVerificationEnabled = 'app_free_email_verification',
  IsMatchaIncludeTreadPlusInReferralsEnabled = 'matcha_include_tread_plus_in_referrals',
  isFreeSevenDayTrialEnabled = 'champ_free_seven_day_trial',
  IsFreeTrialMobilityEnabled = 'mem_1p_free_trial_mobility',
  IsPersonalizedWinbackEnabled = 'is_personalized_winback_enabled',
  IsMatchaReferralPulseEnabled = 'matcha_referral_pulse',
  isAppCheckoutV2Enabled = 'champ_app_checkout_v2',
  i18NLocaleToggle = 'i18n_locale_toggle',
  IsRemoveFaasCancellationFormEnabled = 'is_remove_faas_cancellation_form_enabled',
  isSocialProspectsExperienceEnabled = 'socialprospectsexperience',
  isSpecialPricingPhaseOneEnabled = 'special_pricing_phase_1',
  isSpecialPricingPhaseTwoEnabled = 'special_pricing_phase_2',
  IsSmafGiftingEnabled = 'mem_smaf_gifting_enabled',
}
